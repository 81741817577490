import { graphql, useStaticQuery } from 'gatsby';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import Header from './Header';
import styles from './styles.module.scss';

interface Props {
  className?: string;
  children: React.ReactNode;
  darkMode?: boolean;
}

const metaQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
        someImage
        siteUrl
      }
    }
  }
`;

const Layout = ({ children, className, darkMode }: Props): JSX.Element => {
  const [showGrid, setShowGrid] = useState(false);
  const meta = useStaticQuery(metaQuery);
  const {
    site: {
      siteMetadata: { description, siteUrl, someImage, title },
    },
  } = meta;

  useEffect(() => {
    try {
      const showDebugGrid = JSON.parse(localStorage.getItem('grid'));
      if (showDebugGrid) setShowGrid(true);
    } catch (err) {
      // nada.
    }
  }, []);

  return (
    <>
      <Helmet>
        <html lang="en" />
        <title>uppercase - your digital partner</title>
        <meta charSet="utf-8" />
        <meta name="description" content={description} />
        <meta property="og:title" content={title} />
        <meta property="og:type" content="website" />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={`${siteUrl}${someImage}`} />
        <meta property="og:url" content={siteUrl} />
        <meta property="og:site_name" content={title} />
        <meta name="application-name" content={title} />
      </Helmet>

      <Header darkMode={darkMode} />
      <main className={className}>{children}</main>

      {showGrid && (
        <div className={styles.debugGridContainer}>
          <div className={styles.debugGrid}>
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
          </div>
        </div>
      )}
    </>
  );
};

export default Layout;
