import React from 'react';

interface Props {
  className?: string;
  darkMode?: boolean;
}


const Logo = ({darkMode, className}: Props): JSX.Element => {
  return (
    <div className={className}>
      <svg version="1.1" viewBox="0 0 322 46">
        <defs>
          <polygon id="c" points="0.34534 0.18548 30.289 0.18548 30.289 34.794 0.34534 34.794"/>
          <polygon id="b" points="0 46 322 46 322 1 0 1"/>
        </defs>
        <g fill="none">
          <g transform="translate(-27 -45)">
            <g transform="translate(27 45)">
              <g transform="translate(255)">
                <path d="m0.34534 24.954 8.2867-2.2394c0.55699 4.343 2.9248 5.4969 7.242 5.4969 3.4124 0 5.571-1.1539 5.571-3.3933 0-2.0356-0.97492-3.0536-5.0141-3.6643l-5.7101-0.88211c-5.9886-0.95005-9.6792-4.2751-9.6792-9.9754 0-6.3786 5.0834-10.111 13.649-10.111 9.2616 0 13.44 3.7326 14.554 9.636l-7.799 1.8325c-0.48765-4.2075-2.1586-5.2255-6.4763-5.2255-3.3423 0-5.0137 1.2214-5.0137 3.4609 0 1.8322 0.90521 2.9857 4.2479 3.4609l6.9635 1.018c5.6404 0.74624 9.1222 4.0716 9.1222 9.5002 0 6.8538-5.5707 10.925-14.972 10.925-8.7739 0-14.066-4.4106-14.972-9.8395" fill="#000" mask="url(#d)"/>
              </g>
              <polygon points="158 35 167 35 167 10 158 10" fill="#000" mask="url(#a)"/>
              <path d="m32 1v19.584c0 8.772-6.4829 14.416-16.069 14.416-9.5173 0-15.931-5.644-15.931-14.416v-19.584h8.7585v19.856c0 5.1682 2.2759 6.528 7.1726 6.528 5.0344 0 7.31-1.3598 7.31-6.528v-19.856h8.7589z" fill="#D4D4D4" mask="url(#a)"/>
              <path d="m64.368 18.194c0-6.4477-2.8324-9.4701-8.9692-9.4701-6.2718 0-9.1717 3.0223-9.1717 9.4701 0 6.3136 2.8999 9.3359 9.1717 9.3359 6.1367 0 8.9692-3.0223 8.9692-9.3359m8.6319 0c0 10.209-5.5973 17.06-15.039 17.06-5.3948 0-9.306-2.7537-11.396-7.7239v18.47h-8.5648v-44.328h8.5648v7.2536c2.0905-5.1043 6.2714-7.9253 11.869-7.9253 9.1717 0 14.566 6.8508 14.566 17.194" fill="#000" mask="url(#a)"/>
              <path d="m105.37 18.194c0-6.4477-2.8324-9.4701-8.9692-9.4701-6.2718 0-9.1714 3.0223-9.1714 9.4701 0 6.3136 2.8996 9.3359 9.1714 9.3359 6.1368 0 8.9692-3.0223 8.9692-9.3359m8.632 0c0 10.209-5.5974 17.06-15.039 17.06-5.3952 0-9.3065-2.7537-11.397-7.7239v18.47h-8.5645v-44.328h8.5645v7.2536c2.0905-5.1043 6.2715-7.9253 11.869-7.9253 9.1718 0 14.567 6.8508 14.567 17.194" fill="#000" mask="url(#a)"/>
              <path d="m128.06 14.275h15.3c-0.13737-5.6961-2.4702-7.6864-7.4098-7.6864-4.8709 0-7.2724 2.2648-7.8898 7.6864m23.875 5.9706h-23.875c0.54913 5.6274 3.0189 7.9609 8.3016 7.9609 3.9791 0 5.6944-1.1666 7.3407-4.7353l7.5471 2.745c-2.2641 5.8335-7.5471 8.7843-15.094 8.7843-10.291 0-17.152-6.8627-17.152-17.294 0-10.568 6.9293-17.706 16.877-17.706 9.6052 0 16.123 6.6569 16.123 17.02 0 1.0982 0 1.8529-0.068686 3.2256" fill="#000" mask="url(#a)"/>
              <path d="m201.3 19.628 8.6979 2.059c-2.366 9.5389-9.1155 13.313-17.048 13.313-10.646 0-17.952-6.9997-17.952-17.431 0-10.568 7.3058-17.569 17.952-17.569 8.2803 0 14.821 4.1177 17.048 13.314l-8.6979 2.0587c-1.0438-5.6274-3.4094-7.4803-8.35-7.4803-6.193 0-9.0455 3.0882-9.0455 9.6764 0 6.4511 2.8525 9.5393 9.0455 9.5393 4.8709 0 7.2365-1.8529 8.35-7.4803" fill="#000" mask="url(#a)"/>
              <path d="m241.72 17.569c0-6.5882-2.8944-9.6764-9.154-9.6764-6.0575 0-8.9519 3.0882-8.9519 9.6764 0 6.4511 2.8944 9.5393 8.9519 9.5393 6.2597 0 9.154-3.0882 9.154-9.5393zm8.2787-16.882v33.627h-8.5479v-7.3432c-2.0194 5.1472-5.9905 8.0296-11.442 8.0296-9.4232 0-15.01-7.0001-15.01-17.431 0-10.568 5.3848-17.569 14.606-17.569 5.6536 0 9.7598 2.9511 11.846 8.2353v-7.549h8.5479z" fill="#000" mask="url(#a)"/>
              <path d="m298.06 14.275h15.3c-0.13737-5.6961-2.4702-7.6864-7.4098-7.6864-4.8709 0-7.2724 2.2648-7.8898 7.6864m23.875 5.9706h-23.875c0.54876 5.6274 3.0189 7.9609 8.3016 7.9609 3.9791 0 5.6944-1.1666 7.3407-4.7353l7.5471 2.745c-2.2641 5.8335-7.5471 8.7843-15.094 8.7843-10.291 0-17.152-6.8627-17.152-17.294 0-10.568 6.9293-17.706 16.877-17.706 9.6052 0 16.123 6.6569 16.123 17.02 0 1.0982 0 1.8529-0.068686 3.2256" fill="#000" mask="url(#a)"/>
              <path d="m170.37 10h-3.3694v-9h9v3.3196c0 3.1371-2.521 5.6804-5.6306 5.6804" fill="#000" mask="url(#a)"/>
            </g>
          </g>
        </g>
      </svg>

    </div>
  )
};

export default Logo;
