import classnames from 'classnames';
import React from 'react';
import styles from './styles.module.scss';

interface Props {
  children: React.ReactNode;
  className?: string;
}

const Grid = ({ children, className, style }: Props): JSX.Element => (
  <div className={classnames(styles.gridContainer, className && className)} style={style}>
    <div className={styles.grid}>
      {children}
    </div>
  </div>
);

export default Grid;
