import classnames from 'classnames';
import Grid from 'components/common/Grid';
import { Link } from 'gatsby';
import React, { useState, useRef } from 'react';
import Logo from './Logo';
import styles from './styles.module.scss';

interface Props {
  darkMode?: boolean;
}

const Header = ({darkMode}: Props): JSX.Element => {
  const requestRef = useRef();
  const previousTimeRef = useRef();

  return (
  <Grid className={`${styles.grid} ${styles.Header } header` }>
      <div className={classnames(
        styles.logoContainer,
      )}>
        <Link to="/">
          <Logo darkMode={darkMode} className={styles.logoLarge} />
        </Link>
      </div>
  </Grid>
)};

export default Header;
